.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p{
  font-family: 'Anek Odia';
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Marianne';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/fonts/Marianne-Regular.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Marianne';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: url(assets/fonts/Marianne-Bold.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Marianne';
    font-style: black;
    font-weight: 800;
    font-display: swap;
    src: url(assets/fonts/Marianne-ExtraBold.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Marianne';
    font-style: light;
    font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/Marianne-Light.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Anek Odia';
    font-style: light;
    font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/AnekOdia-Light.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Anek Odia';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: url(assets/fonts/AnekOdia-Bold.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Anek Odia';
    font-style: black;
    font-weight: 800;
    font-display: swap;
    src: url(assets/fonts/AnekOdia-ExtraBold.woff2) format('woff2');
  }
}