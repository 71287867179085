.header-etapes::after{
    content: url('../../assets/images/Stages-Line-Mobile.svg');
    position: absolute;
    top: 450px;
    right: 5px;
    z-index:0;
}

@media (min-width: 768px) {
    .header-etapes::after{
        content: url('../../assets/images/Stages-Line-Tablet.svg');
        height:447px;
        position: absolute;
        top: 220px;
        right: -15px;
        z-index:0;
    }
}

@media (min-width: 1280px) {
    .header-etapes::after{
        content: url('../../assets/images/Stages-Line.svg');
        position: absolute;
        top: 223.5px;
        right: 210px;
        z-index:0;
    }
}

h2>span{
    font-weight:800;
}

.formes{
    position: absolute;
    top: -60px;
    right: -67px;
    scale: 0;
    transition: all 0.3s ease;
}

.stages:hover>.formes{
    display: block;
    scale: 100%;
    top: 0;
    right: 0;
}

h3{
    font-size:20px !important;
    letter-spacing: 1.25px;
    border: 5px solid white;
    border-radius: 5px;
}

.forme-b{
    position: absolute;
    top: -60px;
    right: -70px;
    scale: 0;
    transition: all 0.3s ease;
    z-index: 1;
}

.service:hover>.forme-b{
    display: block;
    scale: 100%;
    top: 0;
    right: 0;
}

.etapes>p{
    width: 100%;
}