.section{
    width:100vw;
    background-image: url(../../assets/images/Hero-Section-IMG-Mobile.svg);
    background-repeat: no-repeat;
    height:454px;
}

@media (min-width: 768px) {
    .section{
        background-image: url(../../assets/images/Hero-Section-IMG-Tablet.svg);
        height:458px;
    }
}

@media (min-width: 1280px) {
    .section{
        background-image: url(../../assets/images/Hero-Section-IMG.svg);
        height:641px;
    }
}

p>.view{
    font-weight: 400;
}