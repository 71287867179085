html{
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1{
  font-family: 'Anek Odia' !important;
  font-size: 35px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 125% !important;
  color: black;
}

h1>span{
  font-weight: 300;
}

p>.bold{
  font-weight:800;
}
p>span>span.bold{
  font-weight:800;
}

h2{
  font-family: "Anek Odia";
  font-size: 26px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 125% !important;
  color: black !important;
}

h3{
  font-family: 'Marianne' !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 125% !important;
  color: black;
}

h4{
  font-family: 'Marianne';
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
  color: black;
}

p{
  font-family:'Marianne' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important; 
  color: black;
}

@media (min-width: 768px) {
  h1{
    font-size: 50px !important;
  }
}

@media (min-width: 1280px) {
  h1{
    font-size: 72px !important;
  }
  h2{
    font-size: 45px !important;
    font-weight: 300 !important;
  }
  h3{
    font-size: 24px !important;
  }
  p{
    font-size: 16px !important;
  }
}

code {
  font-family: 'Anek Odia';
}
