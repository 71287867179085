li{
    height: 30px;
    margin-left: 0 !important;
}

.after::after{
    content: '';
    display: block;
    width: 2px;
    height: 30px;
    background-color: #FFF2DF;
    position: relative;
    top: -30px;
    left: -10px;
    }

    @media (max-width: 1600px) {
    .after::after{
        content: '';
        display: block;
        width: 2px;
        height: 30px;
        background-color: #FFF2DF;
        position: relative;
        top: -30px;
        margin-left: 10px;
        }
    }