.questions-text{
    font-family: 'Marianne';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
    text-align: left;
}

.questions-pictos{
    font-family: '';
    font-size: 30px;
    font-weight: bold;
}

.answers{
    font-family: 'Marianne';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
}

