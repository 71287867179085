.social>h4{
    font-family: 'Marianne';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
}

.footer{
    background-image: url(../../assets/images/Footer-Fond-Piste.svg);
    background-repeat: no-repeat;
    background-position: bottom 0 right 0;
}

.col-1{
    color: white;
}

.col-1>p{
    color: white;
    font-weight: 100 !important;
}

p>span{
    font-weight: 800;
}

h6{
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
}

.list{
    color: white;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
}

li{
    margin-bottom: 5px;
}

h5{
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
}

.contact-footer>a{
    color: white;
    border: 1px solid white;
    border-radius: 15px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

li>a:hover{
    text-decoration: underline;
}