.services{
    margin-left:auto;
    position: relative;
    top:-80px;
}

@media (min-width: 768px) {
    .services{
        top:-40px;
    }
}

@media (max-width: 768px) {
    .services{
        overflow-x: scroll;
        padding-right:10vw;
    }
    .services::-webkit-scrollbar {
        display: none;
    }
}

@media (min-width: 1280px) {
    .services{
        top:-120px;
    }
}


.service>p{
    width: 242px;
    font-family: 'Marianne';
    font-weight: 300 !important;
    font-size:14px !important;
    line-height: 150%;
}

.forme{
    position: absolute;
    top: -40px;
    right: -47px;
    scale: 0;
    transition: all 0.3s ease;
}

.service:hover>.forme{
    display: block;
    scale: 100%;
    top: 0;
    right: 0;
}