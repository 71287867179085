a:hover svg.icones{
    fill: #441922;
}

.pictos-nav{
    font-family: 'Poppins';
}

.navbar{
    background-color: rgba(255, 242, 223,0.75);
}