.thematique{
    font-family: 'Marianne';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
}

.text-theme>h2{
    border-radius: 0px 20px 0px 0px;
    left: 0px;
    top: -45px;
    font-weight:bold !important;
/* padding: 20px 20px 0px 10px;*/
}

.text-theme{
    top:-70px;
}

@media (min-width: 768px) {
    .text-theme{
        border-radius: 0px 0px 0px 20px !important;
        left: -100px;
        top: 0;
        padding-top:0px !important;
        padding-left:20px !important;
        padding-bottom:20px !important;
    }

    .projets-p{
        font-size: 12px !important;
    }

    .infos-theme{
        left: 20px;
        top: 142px;
        width: auto !important; 
    }
    
}

@media (min-width: 1280px) {
    .text-theme{
        border-radius: 0px 0px 0px 20px;
        left: -104px;
        top: 0;
        padding-top:0px !important;
        padding-left:40px !important;
        padding-bottom:40px !important;
    }
    .projets-p{
        font-size: 16px !important;
    }
}

.infos-theme{
    left: 20px;
    top: 142px;
    width: 381px; 
}

.previous{
    top:284px;

}

.slider-btn{
    width: 176px;
    height: 68px;
}

@media (min-width: 1280px) {
    .slider-btn{
        top: 240px;
    }
}    