@media (min-width: 1280px) {
    .projets{
    background-image: url('../../assets/images/Fond-Piste-Projets.svg');
    background-repeat: no-repeat;
    background-position:bottom 80px left 0px;
    }
}

@media (min-width:360px) {
    .app::after{
        background-color:#CFE0FF !important;
    }
}

@media (min-width:768px) {
    .app::after{
        height:150px !important;
        bottom: -100px !important;
    }
}

@media (min-width:1280px) {
    .app::after{
        bottom: 0px !important;
        height: 80px !important;
        background-color:white !important;
    }
}

.projets::before{
    content: '';
    display:block;
    width: 100vw;
    background-color:#CFE0FF !important;
    position: relative;
    height: 70px;
    top: -70px;
    z-index:0;
}

.app::after{
    content: '';
    display:block;
    width: 100vw;
    position: absolute;
    bottom: 0px;
}

/*
.univers>h2{
    width: auto;
}

.univers>p{
    width:486px;
}*/