@media (min-width:1280px) {
    .contact{
        background-image: url('../../assets/images/Fond-Piste-Contact.svg');
        background-repeat: no-repeat;
        background-position:bottom 0px right 0px;
    }
}


.paragraphe{
    font-family: 'Marianne';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 18px */
}